<template>
  <div class="relative">
    <!--start 页脚图片，---有图显示图片-->
    <div
      v-if="block.image && block.logoImageShow"
      class="relative flex justify-center items-center"
      :model="block"
    >
      <img
        :src="block.image"
        class="image"
        alt="assets"
        :style="{
        opacity: block.logoImageOpacity,
        maxHeight: maxHeight + 'px'
      }"
      />
      <CoverBlock
        v-if="editing"
        absolute
        :title="$t('edit.setImage')"
        @click="handleShowBackgroundMenu(block)"
      />
    </div>
    <!--start 页脚图片无图设置图片-->
    <div v-if="editing && !block.image" class="setting-image">
      <CoverBlock :title="$t('edit.setImage')" @click="handleShowBackgroundMenu(block)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterImageWidget",
  components: {
  },
  props: {
    block: {
      type: Object,
      default() {

      }
    },
    isLogo: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      default: 40
    }
  },
  methods: {
    handleShowBackgroundMenu(child) {
      // const child = this.model
      if (this.isLogo) {
        this.SiteMenu.closeShowImage('logo-image')
      } else {
        const menu = {
          key: child.getMenuInfo().title,
          target: child,
          config: {
            // childIndex: index,
          },
          model: child,
        }
        this.$nextTick(() => {
          this.SiteMenu.openMenuShowImage(menu)
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.setting-image {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-image {
  position: relative;
  overflow-y: hidden;
  // .image {
  //   max-width: 150px;
  //   max-height: 40px;
  // }
  .cover-btn {
    position: absolute;
    top: 25%;
  }
}
</style>
