<template>
    <div :style="{position:absolute? 'absolute': 'relative'}" class="cover-block w-full h-full flex items-center justify-center z-10" @mouseenter="logoCoverBtn=true" @mouseleave="logoCoverBtn=true">
        <slot>
             <CoverBtn v-show="logoCoverBtn" v-bind="$attrs" v-on="$listeners"></CoverBtn>
        </slot>
    </div>
</template>
<script>
import CoverBtn from './CoverBtn'
export default {
    name: 'CoverBlock',
    components: {
        CoverBtn
    },
    props:{
        absolute:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            logoCoverBtn: true
        }
    }
} 
</script>

<style lang="less" scoped>
.cover-block {
    border: 2px dashed transparent;
    &:hover {
     border: 2p x dashed @primary-6;
    }
}
</style>
