import { render, staticRenderFns } from "./settingMenu.vue?vue&type=template&id=5efda68a&scoped=true&"
import script from "./settingMenu.vue?vue&type=script&lang=js&"
export * from "./settingMenu.vue?vue&type=script&lang=js&"
import style0 from "./settingMenu.vue?vue&type=style&index=0&id=5efda68a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efda68a",
  null
  
)

export default component.exports