// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sign-activity__list{background-color:#131d21;border-radius:10px;display:flex;flex-wrap:wrap;gap:34px;margin:0 auto;max-width:1280px;padding:2.4rem}.sign-activity__list .item{width:144px}.sign-activity__list .item-pic{background-color:#425e63;border-radius:8px 8px 0 0;height:144px;width:100%}.sign-activity__list .item-name{background:linear-gradient(255deg,#c83b57 3%,#871a30);border-radius:0 0 8px 8px;color:#fff;font-size:12px;line-height:36px}.sign-activity.mobile .sign-activity__list{gap:0;padding:1rem;width:100%}.sign-activity.mobile .sign-activity__list .item{padding:5px;width:25%}.sign-activity.mobile .sign-activity__list .item-pic{height:66px;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
