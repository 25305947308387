<template>
  <bg-style :bg="model" class="sign-activity p-5" :class="{ 'mobile': isMobile }">
    <div v-if="model.titleVisible" class="sign-activity__title">
      <rich-text v-model="model.title" :editing="editing" />
    </div>
    <div v-if="model.subtitleVisible" class="sign-activity__subtitle mt-4">
      <rich-text v-model="model.subtitle" :editing="editing" />
    </div>
    <div class="sign-activity__list relative z-10 mt-6">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="item-pic overflow-hidden">
          <bg-style class="h-full" :bg="model.mark" />
        </div>
        <div class="item-name text-center">{{ item.name }}</div>
      </div>
    </div>
  </bg-style>
</template>

<script>
import RichText from '~/components/richText/index.vue'
import { SignActivityWidgetModel } from '~/site/model/models/SignActivityWidgetModel'
import { DeviceEnum } from '~/enums/deviceEnum'

export default {
  name: 'SignActivityWidget',
  components: {RichText},
  props: {
    model: SignActivityWidgetModel,
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {
      list: [
        {
          pic: '',
          name: '商品名称1'
        },
        {
          pic: '',
          name: '商品名称2'
        },
        {
          pic: '',
          name: '商品名称3'
        },
        {
          name: '商品名称4',
          pic: 4
        },
        {
          pic: '',
          name: '商品名称1'
        },
        {
          pic: '',
          name: '商品名称2'
        },
        {
          pic: '',
          name: '商品名称3'
        },
        {
          name: '商品名称4',
          pic: 4
        }
      ]
    }
  },
  computed: {
    isMobile() {
      return this.device === DeviceEnum.MOBILE
    }
  },
  methods: {
    //
  }
}
</script>

<style lang="less">
.sign-activity {
  &__list {
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    gap: 34px;
    background-color: #131D21;
    border-radius: 10px;
    flex-wrap: wrap;
    padding: 2.4rem;
    .item {
      width: 144px;
      &-pic {
        width: 100%;
        height: 144px;
        border-radius: 8px 8px 0 0;
        background-color: #425E63;
      }
      &-name {
        background: linear-gradient(255deg, #C83B57 3%, #871A30 100%);
        line-height: 36px;
        color: white;
        border-radius: 0 0 8px 8px;
        font-size: 12px;
      }
    }
  }

  &.mobile {
    .sign-activity__list {
      padding: 1rem;
      width: 100%;
      gap: 0;
      .item {
        width: 25%;
        padding: 5px;
        &-pic {
          width: 100%;
          height: 66px;
        }
      }
    }
  }
}
</style>
