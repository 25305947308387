<template>
  <div name="destination">
     <login-widget-modal :device="device" :editing="editing" :model="site && site.loginSetting"></login-widget-modal>
     <support-setting-widget-modal :device="device" :editing="editing" :model="site && site.supportSetting"></support-setting-widget-modal>
  </div>
</template>

<script>
import LoginWidgetModal from '../widgets/loginSetting/index'
import SupportSettingWidgetModal from '../widgets/supportSetting/index'
export default {
  name: "SiteModals",
  components: {
    LoginWidgetModal,
    SupportSettingWidgetModal
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
     return {}
  },
  computed: {

  },
  watch: {},

  mounted() {

  },
  beforeDestroy() {
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

</style>
