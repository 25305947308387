<template>
  <div class="seo-widget-edit-wrap">
    <menu-item class="menu-item-switch" :label="$t('seo.favicon')">
      <menu-background-image :target="model" image-key="favicon" />
    </menu-item>
    <menu-item class="menu-item-switch" :label="$t('seo.inputShareTitle')">
     <base-input v-model="model.title" :value="model.title"/>
    </menu-item>
    <menu-item class="menu-item-switch" :label="$t('seo.inputShareDesc')">
      <base-input v-model="model.desc" :value="model.desc"/>
    </menu-item>
<!--    <menu-item class="menu-item-switch" label="">-->
<!--       <button class="save-btn" @click="saveMethod"> 保存 </button>-->
<!--    </menu-item>-->
  </div>
</template>

<script>
import { SeoSettingModel } from '../../model'
import BaseInput from '~/components/base/baseInput'
import MenuItem from "~/components/menu/menuItem"
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage";

export default {
  name: "SeoSettingEdit",
  components:{
    MenuItem,
    BaseInput,
    MenuBackgroundImage,
  },
  props:{
    model: {
      type: Object,
      default(){
        return new SeoSettingModel()
      }
    }
  },
  data(){
    return {
      tmpModel: {}
    }
  },
  created() {
    // this.tmpModel =  {... this.model}
  },
  methods:{
    // saveMethod(){
    //   Object.assign(this.model,this.tmpModel)
    // },
    handleShowBackgroundMenu(suggestion){
      this.SiteMenu.open({
        key: 'background',
        target: this.model,
        config: {
          imageKey: 'favicon',
          colorKey: '',
          imageSizeKey: '',
          suggestion
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.save-btn{
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 120px;
  background: @primary-color;
  color: @text-color ;
  font-size: 16px;
  &:hover {
    color: @text-light-color;
  }
}
</style>
