<template>
  <widget-content :class="isMobile ? 'image_text_mobile' : 'image_text_desk'" :bg="model">
    <div class="imageText-content">
      <div class="back-color-wrap" :style="backColorStyle"></div>
      <rich-text
        v-if="showTitle"
        v-model="model.imageTextTitle"
        :editing="editing"
        :disabled="!editing"
        theme="snow"
        class="image_text_title relative"
        placement="bottom"
      />
      <!-- item板块 -->
      <div
        ref="imageTextItem"
        :class="['image_text_warp flex flex-col', { 'image_text_empty': !(model.children && model.children.length) }]"
      >
        <div
          v-for="(child, index) in children"
          :key="index"
          :class="['image_text_warp_row', {'justify-center': !isMobile && model.alignMethod === 'center'}]"
        >
          <div
            v-for="(block, idx) in child"
            :key="block.id"
            class="flex items-center justify-center image_text_warp_row_item"
          >
            <ImageTextItem
              :child-index="idx"
              :is-mobile="isMobile"
              :model="model"
              :editing="editing"
              :target="block"
              class="w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  </widget-content>
</template>

<script>
import chunk from 'lodash.chunk'
import ImageTextItem from './item.vue'
import { ImageTextWidgetModel } from '~/site/model/models/imageText'
import RichText from "~/components/richText";
import WidgetContent from '~/site/components/widgetContent'

export default {
  name: "ImageTextWidget",
  components: {
    ImageTextItem,
    RichText,
    WidgetContent,
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default: () => { }
    },
    model: {
      type: Object,
      default() {
        return new ImageTextWidgetModel()
      }
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  computed: {
    backColorStyle() {
      if (this.model?.backgroundColorShow) {
        return this.model.backgroundColor
      }
      return {}
    },
    showTitle() {
      return this.model.imageTextTitleShow
    },
    children() {
      return chunk(this.model.children, 3) || []
    },
    // 是否移动端
    isMobile() {
      return this.device === 'mobile'
    },
  }
}
</script>

<style lang="less" scoped>
.image_text_desk {
  padding: 86px 0;
  .image_text_title {
    font-size: 46px;
    line-height: 70px;
    // text-transform: uppercase;
    letter-spacing: 0em;
    margin-bottom: 46px;
  }

  .image_text_warp {
    row-gap: 32px;
    .image_text_warp_row {
      display: flex;
      column-gap: 32px;
      .image_text_warp_row_item {
        width: calc((100% - 64px) / 3);
      }
    }
  }
}

.image_text_mobile {
  padding: 32px 16px;
  .image_text_title {
    font-size: 24px;
    line-height: 36px;
    // text-transform: uppercase;
    letter-spacing: 0em;
    margin-bottom: 16px;
  }

  .image_text_warp {
    .image_text_warp_row {
      display: flex;
      flex-direction: column;
      .image_text_warp_row_item {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 28px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }
  }
}

.image_text_empty {
  min-height: auto;
  padding: 0px 48px;
}
</style>
