<template>
  <div class="relative" :style="{...customStyle, ...getStyle}">
    <div v-if="bg.backgroundImage" v-show="isShowBackgroundImage" class="absolute top-0 left-0 bottom-0 right-0 pointer-events-none" :style="bgStyle" />
    <div v-if="bg.backgroundColor" v-show="isShowColor" class="absolute top-0 left-0 bottom-0 right-0 pointer-events-none" :style="bgColorStyle" />
    <slot/>
  </div>
</template>

<script>
import {computeBackgroundStyle, isShowColor, isShowBackgroundImage} from '~/site/model/helper'

export default {
  name: 'CommonBackgroundStyle',
  props: {
    bg: {
      type: Object,
      default() {
        return {}
      }
    },
    customStyle: {
      type: Object,
      default() {
        return {}
      }
    },
    radius: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getStyle() {
      const style = {}
      if (this.radius) {
        style.borderRadius = this.radius + 'px'
        style.overflow = 'hidden'
      }
      return style
    },
    isShowColor() {
      return isShowColor(this.bg)
    },
    isShowBackgroundImage() {
      return isShowBackgroundImage(this.bg)
    },
    bgStyle() {
      return computeBackgroundStyle(this.bg, {})
    },
    bgColorStyle() {
      // 经过和ui协商，背景模糊和背景图片只能同时出现。
      // if(!this.bg.backgroundImage && this.bg.backgroundBlur) {
      //    return { backgroundColor: this.bg.backgroundColor || this.bg.background,
      //             filter: this.bg.backgroundBlur? `blur(${this.bg.backgroundBlur}px)`: ''
      //      }
      // }
      return { background: this.bg.backgroundColor || this.bg.background  }
    }
  }
}
</script>

<style scoped>

</style>
