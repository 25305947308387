// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_desk_lang{border:1px solid #ebeef1;border-radius:2px;padding:4px;width:210px}.header_desk_lang .desk_lang{font-family:PingFang SC}.header_desk_lang .header_desk_lang_list{color:#18171c;font-family:PingFang SC;font-size:14px;font-weight:700;line-height:24px;max-height:250px;overflow-y:auto}.header_desk_lang .header_desk_lang_list::-webkit-scrollbar{display:none}.header_desk_lang .header_desk_lang_list_item{border-radius:2px;margin-bottom:2px;padding:7px 12px}.header_desk_lang .header_desk_lang_list_item:hover{background-color:rgba(235,238,241,.6)}.header_desk_lang .active{color:#3d46f5}.mobile_lang_drawer{height:auto!important}.mobile_lang_drawer .el-drawer__header{border-bottom:1px solid #ebeef1;color:#000;font-family:PingFang SC;font-size:20px;font-weight:400;margin-bottom:0;padding:16px}.mobile_lang_drawer .el-drawer__body{max-height:400px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
