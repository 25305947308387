<template>
  <div v-loading="imgLoading" class="assets-item" :class="{ 'active': isActive }">
    <img class="assets-item__pic" :src="url" alt="pic" @load="onImgLoad" @click.stop="handleSelect">
    <popover v-model="popoverVisible" popper-class="assets-item__popper" :visible-arrow="false" placement="bottom-end">
      <div>
        <div class="popover-content flex items-center">
          <i class="el-icon-warning text-danger text-18" />
          <span class="text-white ml-1">确认删除图片吗？</span>
        </div>
        <div class="flex items-center mt-4 w-full justify-end">
          <span class="text-textColor-3 hover:text-white cursor-pointer" @click="handleCancel">取消</span>
          <div class="submit-btn w-[50px] h-[24px] flex items-center text-white justify-center bg-primary cursor-pointer rounded-sm ml-4" @click="handleSubmit">确定</div>
        </div>
      </div>
      <div slot="reference" class="assets-item__delete">
        <icon-delete class="text-white" />
      </div>
    </popover>
  </div>
</template>

<script>
import { Popover } from 'element-ui'
import iconDelete from '@/assets/svg/icon/iconDelete.svg'
export default {
  name: 'AssetsItem',
  components: {
    iconDelete,
    Popover
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popoverVisible: false,
      imgLoading: true
    }
  },
  methods: {
    handleCancel() {
      this.popoverVisible = false
    },
    onImgLoad() {
      this.imgLoading = false
    },
    handleSelect() {
      this.$emit('select', this.url)
      this.handleCancel()
    },
    handleSubmit() {
      this.$emit('remove')
      this.handleCancel()
    }
  }
}
</script>

<style lang="less">
.assets-item__popper {
  background-color: #303030;
  border: 1px solid #303030;
}
.assets-item {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  border-radius: @border-radius-base;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
  &.active {
    border: 1px solid @primary-1;
  }
  &__pic {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__delete {
    position: absolute;
    right: 2px;
    top: 2px;
    width: 18px;
    height: 18px;
    background-color: rgba(24, 23, 28, 0.36);
    border-radius: @border-radius-base;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: white;
      background-color: @danger-color;
    }
  }
}
</style>
