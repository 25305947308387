<template>
<!--  <assets-material :in-widget="false" :upload-text="$t('upload.text')" :limit="5"></assets-material>-->
  <assets-library tip="上传图片大小不超过5M" :limit="5"/>
</template>

<script>
import { AssetsSettingModel } from '../../model'
import AssetsLibrary from '~/site/components/assetsLibrary/index.vue'
// import AssetsMaterial from '../../components/assetsMaterial.vue'
export default {
  name: "AssetsSettingEdit",
  components:{
    // AssetsMaterial,
    AssetsLibrary
  },
  props:{
    model: {
      type: Object,
      default(){
        return new AssetsSettingModel()
      }
    }
  }
}
</script>

<style scoped>

</style>
