import { render, staticRenderFns } from "./deleteBlockDialog.vue?vue&type=template&id=c2865c60&scoped=true&"
import script from "./deleteBlockDialog.vue?vue&type=script&lang=js&"
export * from "./deleteBlockDialog.vue?vue&type=script&lang=js&"
import style0 from "./deleteBlockDialog.vue?vue&type=style&index=0&id=c2865c60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2865c60",
  null
  
)

export default component.exports