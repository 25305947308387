<template>
  <widget-content :bg="model">
    <div
      :class="['collapse-widget relative', isMobile ? 'collapse_content_mobile' : 'collapse_content_desk']"
    >
      <div class="relative break-point">
        <rich-text
          v-show="model.showTitle"
          v-model="model.title"
          class="collapse-title text-center site-media"
          :editing="editing"
          :disabled="!editing"
          placement="bottom"
        />
        <BaseCollapse :duration="500" :no-enter="false" :no-leave="false">
          <collapse-item
            v-for="(child,index) in model.children "
            :key="child.title + index"
            :class="['collapse-widget-item', { 'collapse-widget-item_nobg' : !isHaveBgColor}]"
            :name="index.toString()"
            :arrow-color="arrowColor"
            :item-bg-style="itemBgStyle"
          >
            <template v-if="child.titleShow">
              <div slot="title" class="flex-1 collapse-widget-item__title">
                <rich-text v-model="child.title" :editing="editing" :disabled="!editing" />
              </div>
              <div
                v-if="!isMobile"
                :class="model.layoutMethod === 'horizontal' ? 'collapse-item_horizontal' : 'collapse-item_vertical'"
              >
                <div
                  v-if="child.backgroundImageShow"
                  :class="['collapse-widget-item__row-img', {'mt-[16px]': model.layoutMethod === 'vertical' && child.descriptionShow}]"
                >
                  <bg-style :bg="child" class="w-full h-full">
                    <div
                      class="collapse-image"
                      :class="{ 'is-work': !editing }"
                      @click="handleShowBackground(child, index)"
                    >
                      <CoverBlock v-if="editing" :title="$t('edit.setImage')" />
                    </div>
                  </bg-style>
                </div>
                <div v-if="child.descriptionShow" class="flex-1 w-full">
                  <rich-text
                    v-model="child.description"
                    class="collapse-widget-item__description w-full"
                    :editing="editing"
                    :disabled="!editing"
                  />
                </div>
              </div>
              <div v-if="isMobile" class="collapse-item_vertical">
                <rich-text
                  v-if="child.descriptionShow"
                  v-model="child.description"
                  class="collapse-widget-item__description site-media w-full"
                  :editing="editing"
                  :disabled="!editing"
                />
                <div
                  v-if="child.backgroundImageShow"
                  :class="['collapse-widget-item__row-img', {'mt-[8px]': child.descriptionShow}]"
                >
                  <bg-style :bg="child" class="w-full h-full">
                    <div
                      class="collapse-image"
                      :class="{ 'is-work': !editing }"
                      @click="handleShowBackground(child, index)"
                    >
                      <CoverBlock v-if="editing" :title="$t('edit.setImage')" />
                    </div>
                  </bg-style>
                </div>
              </div>
            </template>
          </collapse-item>
        </BaseCollapse>
      </div>
    </div>
  </widget-content>
</template>

<script>
import Collapse from '~/site/components/forms/collapse/index.vue'
import CollapseItem from '~/site/components/forms/collapse/item.vue'
import RichText from '~/components/richText'
import { CollapseWidgetModel } from '~/site/model/models/CollapseWidgetModel'
import WidgetContent from '~/site/components/widgetContent'

export default {
  name: 'CollapseWidget',
  components: {
    BaseCollapse: Collapse,
    CollapseItem,
    RichText,
    WidgetContent,
  },
  directives: {},
  props: {
    model: {
      type: Object,
      default() {
        return CollapseWidgetModel
      },
    },
    device: {
      type: String,
      default: 'desktop',
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapse: false,
    }
  },
  computed: {
    arrowColor() {
      if (this.model?.arrow?.backgroundColor) {
        return this.model.arrow.backgroundColor
      }
      return '';
    },
    lineStyle() {
      return this.model?.line?.backgroundColor || 'rgba(204,204,204, 1)'
    },
    // 是否移动端
    isMobile() {
      return this.device === 'mobile'
    },
    // 折叠背景
    isHaveBgColor() {
      return this.model.bgConfig.backgroundColorShow
    },
    itemBgStyle() {
      const { bgConfig } = this.model
      const { backgroundColorShow, backgroundColor } = bgConfig
      if (backgroundColorShow) {
        return { backgroundColor }
      }
      return {}
    },
  },
  methods: {
    handleShowBackground(child, index) {
      this.model.__onMenuSelected({ child }, this)
      const menu = {
        key: child.getMenuInfo().title,
        target: child,
        config: {
          childIndex: index,
        },
        model: this.model,
      }
      this.$nextTick(() => {
        this.SiteMenu.openMenuShowImage(menu)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.collapse_content_desk {
  max-width: 960px;
  margin: 0 auto;
  padding: 86px 0;
  .collapse-title {
    font-size: 46px;
    line-height: 70px;
    margin-bottom: 32px;
    // text-transform: uppercase;
    letter-spacing: 0em;
  }

  .collapse-widget-item {
    // 横向布局
    .collapse-item_horizontal {
      display: flex;
      align-items: center;
      .collapse-widget-item__row-img {
        width: 320px;
        height: 180px;
        border-radius: 4px;
        margin-right: 16px;
      }
    }
    // PC端纵向布局
    .collapse-item_vertical {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      .collapse-widget-item__row-img {
        width: 400px;
        height: 225px;
        border-radius: 4px;
        // margin-top: 16px;
      }
    }

    .collapse-widget-item__description {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.collapse_content_mobile {
  width: 100%;
  padding: 32px 16px 28px;
  .collapse-title {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
    // text-transform: uppercase;
    letter-spacing: 0em;
  }

  .collapse-widget-item {
    .collapse-item_vertical {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .collapse-widget-item__row-img {
        width: 100%;
        height: 153px;
        border-radius: 2px;
        // margin-top: 8px;
      }

      .collapse-widget-item__description {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.collapse-widget {
  .collapse-title {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: var(--title-background-image);
  }
  .collapse-widget-item {
    border-radius: 1px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .collapse-image {
      width: 100%;
      height: 100%;
      position: relative;
      border: 1px dashed @error-color;
      &.is-work {
        border: none;
      }
    }
  }

  .collapse-widget-item_nobg {
    margin-bottom: 0px !important;
  }
}
</style>
<style lang="less">
.collapse_content_desk {
  .site-collapse-item__header {
    min-height: 74px;
    padding: 16px 36px;
    border-radius: 1px;

    .collapse-widget-item__title {
      font-size: 20px;
      line-height: 30px;
    }

    .site-collapse-item__arrow {
      font-size: 24px;
      margin-left: 16px;
    }
  }

  .site-collapse-item__wrap {
    padding: 16px 36px;

    .site-collapse-item__content {
      padding-bottom: 0;
    }
  }

  .collapse-widget-item_nobg {
    .site-collapse-item__wrap {
      padding: 0px 36px;
    }
  }
}

.collapse_content_mobile {
  .site-collapse-item__header {
    min-height: 40px;
    padding: 9px 16px;
    border-radius: 1px;

    .collapse-widget-item__title {
      font-size: 14px;
      line-height: 22px;
    }

    .site-collapse-item__arrow {
      font-size: 16px;
      margin-left: 12px;
    }
  }

  .site-collapse-item__wrap {
    padding: 12px 30px;

    .site-collapse-item__content {
      padding-bottom: 0;
    }
  }

  .collapse-widget-item_nobg {
    .site-collapse-item__wrap {
      padding: 0px 30px;
    }
  }
}
</style>
