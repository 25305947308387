<template>
  <div :class="isMobile ? 'image_text_item_mobile' : 'image_text_item_desk'">
    <bg-style
      v-if="target.backgroundImageShow"
      :bg="target"
      :class="['image_text_item_img w-full relative', {'mb-4': !isMobile && (isTitleShow || isDescriptionShow)}, {'mb-3': isMobile && (isTitleShow || isDescriptionShow)}]"
    >
      <div
        class="flex items-center justify-center w-full h-full relative image_text-image"
        :class="{ 'is-work': !editing }"
        @click="handleShowBackground(target)"
      >
        <CoverBlock v-if="editing" :title="$t('edit.setImage')" />
      </div>
    </bg-style>
    <div class="image_text_item_text relative">
      <rich-text
        v-if="isTitleShow"
        v-model="target.title"
        :editing="editing"
        :disabled="!editing"
        :placement="getPlacement()"
        theme="snow"
        :class="['text_item_title',{'mb-4': !isMobile && isDescriptionShow}, {'mb-2': isMobile && isDescriptionShow}]"
      />
      <rich-text
        v-if="isDescriptionShow"
        v-model="target.description"
        :disabled="!editing"
        :editing="editing"
        :placement="getPlacement()"
        theme="snow"
        class="text_item_desc"
      />
    </div>
    <a
      v-if="(target.url && target.url.startsWith('http') && !editing)"
      class="imageText-link"
      :alt="target.url"
      target="__blank"
      :href="(target.url && target.url.startsWith('http')) ? target.url: 'javascript:void(0);'"
    ></a>
  </div>
</template>

<script>

import { ImageTextItemWidgetModel } from '../../model/models/imageTextItem'
import RichText from "~/components/richText";

export default {
  name: "ImageTextItemWidgetModel",
  components: {
    RichText,
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    target: {
      type: Object,
      default() {
        return new ImageTextItemWidgetModel()
      }
    },
    editing: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    childIndex: {
      type: [Number, undefined],
      default() {
        return undefined
      },
    }
  },
  computed: {
    isTitleShow() {
      if (!this.target.titleShow) {
        return false;
      }
      return this.model.imageTextItemTitleShow
    },
    isDescriptionShow() {
      if (!this.target.descriptionShow) {
        return false;
      }
      return this.model.imageTextItemDescribeShow
    }
  },
  methods: {
    handleShowBackground(target) {
      const menu = {
        key: target.getMenuInfo().title,
        target,
        config: {
          childIndex: this.childIndex,
        },
        model: this.model,
      }
      this.$nextTick(() => {
        this.SiteMenu.openMenuShowImage(menu)
      })
    },
    handleShowBackgroundMenu() {

    },
    lazyScrollContainer() {
      return this.editing ? '' : '.site-content'
    },

    getPlacement() {
      if (!this.isMobile) {
        return this.childIndex === 0 ? 'left-top' : this.childIndex === 2 ? 'right-top' : 'top'
      }
      return 'top'
    }
  }
}
</script>

<style lang="less" scoped>
.image_text_item_desk {
  .image_text_item_img {
    height: 270px;
    border-radius: 1px;
  }

  .text_item_title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0em;
  }

  .text_item_desc {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0em;
  }
}

.image_text_item_mobile {
  .image_text_item_img {
    height: 228px;
    border-radius: 1px;
    margin-bottom: 12px;
  }

  .text_item_title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .text_item_desc {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
  }
}

.image_text-image {
  border: 1px dashed @error-color;
  &.is-work {
    border: none;
  }
}
</style>
