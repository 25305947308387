<template>
  <transition name="fade">
    <div v-if="visible" class="im-message" :class="type">
      <!-- todo 等上传替换 -->
      <im-icon :icon='"icon-"+type'></im-icon>
      <div class="content">{{content}}
        <i v-if="hasClose" class="btn-close iconfont icon-close" @click="visible=false"></i>
      </div>
    </div>
  </transition>
</template>

<script>
  // 'success','warning','error'
  export default {
    name: "MyMessage",
    data() {
      return {
        content: '',
        time: 3000,
        visible: false,
        type:'info',
        hasClose:false,
      }
    },
    mounted() {
      this.close()
    },
    methods: {
      close() {
        window.setTimeout(() =>{
          this.visible = false
        }, this.time);
      }
    }
  }
</script>
<style lang="less" scoped>
  .im-message {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3002;
    width: 457px;
    border-radius: 2px;
    max-height: 220px;
    overflow-y: auto;
    padding: 15px 16px;
    .im-icon {
      margin-right: 16px;
      font-size: 26px;
      width: 25px;
      float: left;
    }
    .content {
      text-align: left;
      word-break: break-all;
      color: #1F1D29;
      font-size: 16px;
      float: left;
      width: 376px;
    }
  }
  .success{
    background: #E6F7EA;
  }
  .info{
    background: #EFEDFD; 
  }
  .warning {
    background: #FFF2E6;;
  }
  .error {
    background: #FEECEC;
  }
</style>
<style lang="less">
  // 750 样式
  .site-device-750 {
    .im-message {
      max-width: 80%;
      max-height: 120px;
      overflow-y: auto;
      .content {
        width: 220px;
      }
    }
  }
</style>
