<template>
  <div>
    <BackgroundGood
      v-if="isBackgroundGoods"
      v-bind="$attrs"
      :model="model"
      v-on="$listeners"
    />
    <NormalGood
      v-else
      v-bind="$attrs"
      :model="model"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import NormalGood from './normal.vue'
import BackgroundGood from './background.vue'

export default {
  name: 'OneGood',

  components: {
    NormalGood,
    BackgroundGood,
  },

  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  computed: {
    /**
     * 商品是否拥有背景布局
     */
    isBackgroundGoods() {
      return this.model.goodsLayoutMethod === 'background'
    }
  },
}
</script>
