<template>
  <div class="sign-activity-edit">
    <menu-item label="背景设置">
      <menu-background-image :target="model" target-key="backgroundImage" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" />
      <menu-switch v-model="model.subtitleVisible" label="描述" />
    </menu-item>
    <menu-item label="已领取标识">
      <menu-background-image :target="model.mark" target-key="mark" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import RemoveBtn from "~/components/common/RemoveBtn.vue";
import MenuItem from "~/components/menu/menuItem.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import { SignActivityWidgetModel } from "~/site/model/models/SignActivityWidgetModel";
import MenuSwitch from "~/components/menu/menuSwitch.vue";

export default {
  name: 'SignActivityWidgetEdit',
  components: {MenuSwitch, MenuBackgroundImage, MenuItem, RemoveBtn},
  props: {
    model: SignActivityWidgetModel
  },
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped lang="less">

</style>
