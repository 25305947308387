<template>
  <div v-show="langList.length > 0" class="lang-select-component">
    <div v-if="device === 'desktop'">
      <Popover
        v-model="visible"
        trigger="manual"
        :placement="source === 'header' ? 'bottom' : 'top'"
        popper-class="header_desk_lang"
      >
        <div
          slot="reference"
          class="desk_lang cursor-pointer flex items-center"
          @click.stop.prevent="handleShowLang(1)"
        >
          <span class="text-16 mr-2">{{ label }}</span>
          <i v-if="!visible" class="el-icon-arrow-down" />
          <i v-if="visible" class="el-icon-arrow-up" />
        </div>
        <div class="header_desk_lang_list">
          <div
            v-for="item in langList"
            :key="item.value"
            :class="['header_desk_lang_list_item cursor-pointer', {'active': item.value === value}]"
            @click.stop="langChangeMethod(item)"
          >{{ item.local }}</div>
        </div>
      </Popover>
    </div>
    <div v-if="device === 'mobile' && source === 'header'" class="w-full">
      <div
        class="header_mobile_lang flex items-center justify-between text-16 leading-6"
        @click.stop.prevent="handleShowLang(2)"
      >
        <span>{{ label }}</span>
        <i v-if="!showLang" class="el-icon-arrow-right" />
        <i v-if="showLang" class="el-icon-arrow-down" />
      </div>
      <div v-if="showLang && !editing" class="header_mobile_lang_list">
        <div
          v-for="item in langList"
          :key="item.value"
          :class="['header_mobile_lang_list_item  flex items-center', {'active': item.value === value}]"
          @click.stop="langChangeMethod(item)"
        >
          <div class="item_line" />
          <div class="cursor-pointer">{{ item.local }}</div>
        </div>
      </div>
    </div>
    <div v-if="device === 'mobile' && source === 'footer'" class="footer_mobile_lang">
      <div
        class="flex items-center px-[12px] py-[6px] cursor-pointer text-14 leading-5"
        @click.stop="handleShowLang(3)"
      >
        <span>{{ label }}</span>
        <i class="el-icon-arrow-down ml-2" />
      </div>
      <Drawer
        :visible.sync="showDrawer"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        :append-to-body="false"
        custom-class="mobile_lang_drawer"
        direction="btt"
      >
        <div slot="title" class="text-center">{{ label }}</div>
        <div class="footer_mobile_lang_list">
          <div
            v-for="item in langList"
            :key="item.value"
            :class="['footer_mobile_lang_list_item cursor-pointer', {'active': item.value === value}]"
            @click.stop="langChangeMethod(item)"
          >{{ item.local }}</div>
        </div>
      </Drawer>
    </div>
  </div>
</template>

<script>
import { Drawer, Popover } from 'element-ui'
import { useAssets } from '~/hooks/useAssets';
import tool from '~/utils';

export default {
  name: "LangSelectComponent",
  components: { Popover, Drawer },
  props: {
    source: {
      type: String,
      default: 'header'
    },
    device: {
      type: String,
      default: 'desktop'
    },
    editing: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data() {
    return {
      value: '',
      label: '',
      visible: false,
      showLang: false,
      showDrawer: false
    }
  },
  computed: {
    langList() {
      const { siteLangList } = useAssets(this)
      const langs = this.unique(siteLangList)
      this.setLabelValue(siteLangList)
      return langs || []
    }
  },
  mounted() {
    document.body.addEventListener('click', this.clickOut)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.clickOut)
  },
  methods: {
    handleShowLang(val) {
      if (!this.editing && val === 1) {
        this.visible = !this.visible
        this.$emit('open')
      }
      if (!this.editing && val === 2) this.showLang = !this.showLang
      if (!this.editing && val === 3) this.showDrawer = true
    },
    clickOut() {
      this.visible = false
    },
    setLabelValue(siteLangList) {
      const langList = siteLangList
      const l = tool.getNeedParamsFormRouteParams(this.$route.params).lang

      if (langList && langList.length > 0) {
        const findItem = langList.find(item => {
          return item.value === l
        })
        if (!findItem) {
          this.value = langList[0].value
          this.label = langList[0].local
        } else {
          this.value = findItem.value
          this.label = findItem.local
        }
      }
    },
    langChangeMethod(item) {
      this.value = item.value
      this.label = item.local
      this.visible = false
      this.showLang = false
      this.showDrawer = false
      this.$emit('close')

      const { params, path } = this.$route
      const { sid, pageid } = tool.getNeedParamsFormRouteParams(params)
      const prefix = path.includes('/preview') ? `/preview/${sid}` : ''
      const page_id = pageid === '/' ? '' : `${pageid}`
      const val = item.value === 'en' ? 'en' : item.value
      this.$cookies.set('lang', val)
      const fullPath = `${prefix}/${val}/${page_id}`
      !this.editing && this.$router.push(fullPath)
    },
    unique(arr) {
      const obj = {};
      return arr.reduce(function (item, next) {
        obj[next.code] ? '' : obj[next.code] = true && item.push(next);
        return item;
      }, []);
    }
  }
}
</script>

<style lang="less" scoped>
.lang-select-component {
  display: inline-block;
  font-family: Source Han Sans CN;
  font-size: 14px;
}

.header_mobile_lang {
  font-family: PingFang SC;
  padding: 16px;
}
.header_mobile_lang_list {
  padding: 6px 16px 0px;

  .header_mobile_lang_list_item {
    font-family: PingFang SC;
    font-size: 16px;
    padding: 16px 0;
    border-left: 1px solid #ebeef1;

    .item_line {
      width: 24px;
      height: 1px;
      margin-right: 12px;
      background-color: #ebeef1;
    }

    &:last-child {
      padding: 16px 0 0;
      align-items: flex-end !important;

      .cursor-pointer {
        margin-bottom: -12px;
      }
    }
  }

  .active {
    color: #3d46f5;
  }
}

.footer_mobile_lang_list {
  padding: 16px 0;
  .footer_mobile_lang_list_item {
    font-family: PingFang SC;
    font-size: 16px;
    letter-spacing: 0em;
    padding: 16px 32px;
    color: #000000;
  }

  .active {
    color: #3d46f5;
  }
}
</style>
<style lang="less">
.header_desk_lang {
  width: 210px;
  padding: 4px;
  border: 1px solid #ebeef1;
  border-radius: 2px;

  .desk_lang {
    font-family: PingFang SC;
  }

  .header_desk_lang_list {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: #18171c;
    max-height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header_desk_lang_list_item {
    padding: 7px 12px;
    border-radius: 2px;
    margin-bottom: 2px;
    &:hover {
      background-color: rgba(235, 238, 241, 0.6);
    }
  }

  .active {
    color: #3d46f5;
  }
}

.mobile_lang_drawer {
  height: auto !important;

  .el-drawer__header {
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: normal;
    color: #000000;
    padding: 16px;
    margin-bottom: 0;
    border-bottom: 1px solid #ebeef1;
  }

  .el-drawer__body {
    max-height: 400px;
  }
}
</style>
