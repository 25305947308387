<template>
  <Form
    ref="formRef"
    class="reset-password-form"
    :model="formData"
    :rules="getFormRules"
    layout="vertical"
    @submit.stop.prevent="handleReset"
  >
    <div
      class="sub-title mt-2 mb-[32px]"
      :style="{ color: mainTextColor }"
    >{{ $t('siteBuild.header.findBankPasswordDesc') }}</div>
    <FormItem name="email" :label="$t('siteBuild.header.email')" prop="email" class="enter-x">
      <!-- :label="isMobile ? $t('siteBuild.header.email') : ''" -->
      <Input
        v-model.trim="formData.email"
        :style="mainButtonColor"
        class="fix-auto-fill mt-2"
        size="large"
        :clearable="true"
        :placeholder="$t('siteBuild.header.email')"
      />
    </FormItem>
    <FormItem prop="password" :label="$t('siteBuild.header.password')">
      <!-- :label="isMobile ? $t('siteBuild.header.password') : ''" -->
      <InputPassword
        v-model.trim="formData.password"
        :style="mainButtonColor"
        class="mt-2"
        size="large"
        type="password"
        :clearable="true"
        :visibility-toggle="true"
        :placeholder="$t('siteBuild.header.password')"
        @change="passwordChange"
      />
    </FormItem>
    <FormItem prop="confirm" :label="$t('siteBuild.header.confirmPassword')">
      <!-- :label="isMobile ? $t('siteBuild.header.confirmPassword') : ''" -->
      <InputPassword
        v-model.trim="formData.confirm"
        :style="mainButtonColor"
        class="mt-2"
        size="large"
        type="password"
        :clearable="true"
        :visibility-toggle="true"
        :placeholder="$t('siteBuild.header.confirmPassword')"
        @blur="handleConfirmBlur"
      />
    </FormItem>
    <FormItem prop="code" :label="$t('siteBuild.header.smsCode')">
      <!-- :label="isMobile ? $t('siteBuild.header.smsCode') : ''" -->
      <div>
        <Input
          v-model.trim="formData.code"
          :style="mainButtonColor"
          :label="$t('siteBuild.header.smsCode')"
          class="flex-1 mt-2"
          size="large"
          :maxlength="6"
          :clearable="true"
          autocomplete="new-password"
          :placeholder="$t('siteBuild.header.smsCode')"
        >
          <Countdown
            ref="cuntDown"
            slot="suffix"
            class="text-right"
            style="margin-left: 24px;"
            :disabled="sendCodeBtnDisabled"
            @click.native="handlerSendSmsCode"
          >{{ $t('siteBuild.header.send') }}</Countdown>
        </Input>
        <div
          v-show="sendMailSuccess"
          style="line-height: 22px;"
        >{{ $t('siteBuild.header.sendMailSuccess') }}</div>
      </div>
    </FormItem>
    <FormItem>
      <Button
        :class="!isMobile ? 'w-full h-[56px]  mt-[16px]' : 'w-full h-[48px]'"
        size="large"
        html-type="submit"
        :normal="true"
        :style="mainButtonColor"
        :disabled="registerBtnDisabled"
        :loading="loading"
        @click.stop.prevent="handleReset"
      >{{ $t('siteBuild.header.resetSignIn') }}</Button>
    </FormItem>
  </Form>
</template>
<script>
import colorMixin from './colorMixins'
import mobileMixin from './mobileMixins'

import { useFormRules, useLoginState, useFormValid } from './useLogin'
import Countdown from './countdown'
import { Form, FormItem, Input } from '~/site/components/forms'
import Button from '~/site/components/forms/button'

export default {
  name: 'ResetPassword',
  components: {
    Form,
    FormItem,
    Input,
    InputPassword: Input, // : Input.Password
    Button,
    Countdown,
  },
  mixins: [colorMixin, mobileMixin],
  props: {
    siteId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      required: true,
      default() {
        return ''
      },
    },

    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { resetPasswordFormRules } = useFormRules(this.formData, this)
    const { handleBackLogin } = useLoginState()
    return {
      formData: {
        email: '',
        password: '',
        confirm: '',
        code: '',
      },
      sendMailSuccess: false,
      getFormRules: resetPasswordFormRules,
      resetSuccess: false,
      loading: false,
      handleBackLogin,
      confirmDirty: false
    }
  },
  computed: {
    sendCodeBtnDisabled() {
      return !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.formData.email)
    },
    registerBtnDisabled() {
      if (this.editing) {
        return false
      }
      const { password, confirm, code } = this.formData
      let result = false
      if (this.sendCodeBtnDisabled) {
        result = true
      } else if (password !== confirm) {
        result = true
      } else if (!code) {
        result = true
      }
      return result
    },
  },
  methods: {
    handleBackLoginMethod() {
      this.handleBackLogin()
    },
    handlerSendSmsCode() {
      if (this.editing) {
        return
      }
      this.sendMailSuccess = false
      this.$refs.formRef.validateField('email', (errorMessage) => {
        if (!errorMessage) {
          const { email } = this.formData
          this.$logger.captureMessage(email + '忘记密码发送验证码中。。。')

          this.$api.siteBuild
            .sendEmail({ ...this.formData, site_id: this.siteId })
            .then((res) => {
              this.$logger.captureMessage(email + '忘记密码发送验证码成功')
              this.sendMailSuccess = true
              this.$refs.cuntDown.start()
            })
            .catch((err) => {
              this.$logger.captureMessage(email + '忘记密码发送验证码失败')
              this.$emit('show-alert', err, 'error')
            })
        }
      })
    },
    handleReset() {
      if (this.editing) {
        return
      }
      this.$logger.captureMessage('重置密码点击')
      // todo  重置之后登录
      const { validForm } = useFormValid(this.$refs.formRef)
      validForm().then((data) => {
        const { email } = this.formData
        if (data && !this.loading) {
          this.$logger.captureMessage(email + '重置密码中。。。')
          this.loading = true
          this.$api.siteBuild
            .resetPassword({
              ...this.formData,
              verification_code: this.formData.code,
              site_id: this.siteId,
            })
            .then((res) => {
              this.$logger.captureMessage(email + '重置密码成功')
              this.handleBackLoginMethod()
            })
            .catch((err) => {
              this.$logger.captureMessage(email + '重置密码失败')
              this.sendMailSuccess = false
              this.$emit('show-alert', err)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    passwordChange(value) {
      this.validateToNextPassword(value)
    },

    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },

    validateToNextPassword(value) {
      if (this.$refs?.formRef && value && this.confirmDirty) {
        this.$refs?.formRef?.validateField(['confirm'])
      }
    },
  },
}
</script>
<style lang="less" scoped>
.reset-password-form {
  .sub-title {
    position: relative;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
  }
}

.sit-count-down {
  border-color: transparent;
  color: #5a47ee;
  background: transparent;

  &:disabled {
    border: 1px solid transparent;
    color: #c9c9d4;
    background: transparent;
    cursor: not-allowed;
  }

  &:hover {
    border-color: transparent;
    color: #5a47ee;
    background: transparent;
  }
}

.@{mobile-cls} {
  .sit-count-down {
    border-color: transparent;
    color: #5a47ee;
    background: transparent;

    &:disabled {
      border: 1px solid transparent;
      color: #c9c9d4;
      background: transparent;
      cursor: not-allowed;
    }
  }
}
</style>
