<template>
  <div class="menu-backgroundImage cursor-pointer flex items-center px-2 border border-transparent hover:border-primary rounded-sm relative" :style="{border: isFocus? '0.5px solid rgba(255, 255, 255, 0.6)': '' }"  :class="{'border-b': borderBottom}">
    <div class="flex items-center justify-between row">
      <Popover  :key="target.id" v-model="visible"  placement="left-start"  popper-class="bg-transparent border-transparent shadow-none popper-wrapper" :visible-arrow="false" trigger="click" @after-enter="onPopoverShow">
        <div slot="reference" class="flex items-center justify-start popover-trigger color">
          <div class="color-item" :style="{ backgroundImage: target[imageKey] ? `url(${target[imageKey]})`: `url(${placeholderImg})`}">
          </div>
          <div class="color-val">{{$t('menu.image')}}</div>
        </div>
        <div class="menu-add-popper menu-bg-add-popper">
          <div class="edit-content__right">
            <PopperBackground ref="background" :key="target.id" :model="model" :target-key="targetKey" :image-key="imageKey" :target-index="targetIndex" :image-size-key="imageSizeKey" :color-key="colorKey" :target="target" :limit="limit" :suggestion="suggestion" @close="close" @change="onImageChange"></PopperBackground>
          </div>
        </div>
      </Popover>
      <base-input v-if="isFocus" v-model="opacity" class="per" @blur="onBlur" @focus="onFocus"></base-input>
      <base-input v-else-if="hasOpacityKeyTarget()" :value="`${Math.round(Number(opacity)*100)/100}%`" class="per" @blur="onBlur" @focus="onFocus"></base-input>
      <eye v-if="hasEyeKeyTarget()" :visible="target[eyeKey]" @visible-change="onShowChange"></eye>
    </div>
  </div>

</template>

<script>
// import tinycolor from 'tinycolor2'
import { Popover } from 'element-ui'
import placeholderImg from '~/assets/img/img-placeholder.png'
// import { getAlpha } from '~/utils/colors'
import eye from '~/components/common/eye.vue'
import PopperBackground from '~/site/components/editMenu/popperBackground.vue'
import BaseInput from '~/components/base/baseInput'
import {accMul, accDiv} from '~/utils/math'
import {insertStep} from "~/utils/event";


export default {
  name: 'MenuBackgroundImage',
  components: {
    eye,
    PopperBackground,
    Popover,
    BaseInput,
  },
  props: {
    eventKey:{
      type: String,
      default: '',
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    targetKey: {
      type: String,
      default: ''
    },
    targetIndex: {
      type: [Number, String],
      default: ''
    },
    target: {
      type: Object,
      default: () => {
        return {}
      },
    },
    imageKey: {
      type: String,
      default: 'backgroundImage',
    },
    colorKey: {
      type: String,
      default: 'backgroundColor',
    },
    imageSizeKey: {
      type: String,
      default: 'backgroundSize',
    },
    imageOpacityKey: {
      type: String,
      default: 'backgroundOpacity',
    },
    eyeKey: {
      type: String,
      default: 'backgroundImageShow',
    },
    suggestion:{
      type: Object,
      default(){
        return null
      },
    },
    limit: {
      type: Number,
      default: 2 // 2Mb
    },
  },

  data() {
    return {
      visible: false,
      isFocus: false,
      opacity: 0,
      placeholderImg
    }
  },
  computed: {},
  watch: {
     target: {
        handler(val) {
          if(this.hasOpacityKey(val)){
            this.opacity = accMul(val[this.imageOpacityKey], 100)
          }
        },
        immediate: true,
        deep: true
    }
  },
  mounted() {
    this.__eventKey = 'show-bg-' + this.eventKey
    this.onShowEvent()
  },
  beforeDestroy() {
    this.SiteMenu.$bus.$off(this.__eventKey, this.handleShow)
  },
  methods: {
    onImageChange(img) {
      this.$emit('image-change', img)
    },
    onPopoverShow() {
      this.$refs.background.initAssetsMaterial()
    },
    canShow(){
      if(this.SiteMenu.selected.length  === 0){
        return 2
      }else{
        const id = 'edit-menu-wrap-' +  (this.SiteMenu.selected.length -1)
        const el = this.$el
        const parent = document.getElementById(id)
        if(parent && parent.contains){
         return  +parent.contains(el)
        }
      }
      return 0
    },
    onShowEvent(){
      this.SiteMenu.$bus.$on( this.__eventKey,this.handleShow)
    },
    hasOpacityKey(val){
      return val && Object.prototype.hasOwnProperty.call(val, this.imageOpacityKey)
    },
    hasOpacityKeyTarget(){
      return this.imageOpacityKey && this.hasOpacityKey(this.target)
    },
    hasEyeKeyTarget(){
      return  this.eyeKey && Object.prototype.hasOwnProperty.call(this.target, this.eyeKey)
    },
    close() {
      this.visible = false
    },
    onShowChange() {
      const oldValue = this.target[this.eyeKey]
      this.target[this.eyeKey] = !this.target[this.eyeKey]
      this.$emit('visible-change', this.target[this.eyeKey])
      insertStep(this, [this.targetKey, this.targetIndex, this.eyeKey], oldValue)
    },
    handleShow() {
      const canShow = this.canShow()
      if(canShow > 1){
        this.visible = true
      }else{
        setTimeout(()=>{
          this.canShow() && (this.visible = true)
        },350)
      }
    },
    onBlur(value) {
      this.isFocus = false;
      if(this.opacity > 100) this.opacity = 100;
      if(this.opacity < 0) this.opacity = 0;
      this.target[this.imageOpacityKey] = accDiv(this.opacity , 100);
      this.$emit('opacity-change', this.target[this.imageOpacityKey])
    },
    onFocus(event) {
      this.isFocus = true
      this.$nextTick(() => {
        event.target.setSelectionRange(0, event.target.value.length)
      })
    },
  },
}
</script>

<style lang="less" scoped>

.menu-bg-add-popper {
  width: 220px;
  position: relative;
  left: -10px;
}

.menu-backgroundImage {
  position: relative;
  background: @fill-color-2;
  border-radius: 2px;
  transition: all 0.4s ease;
  height: 32px;
  border: 0.5px solid transparent;
  .per {
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    padding-left: 5px;

    /deep/ input {
      background: transparent;
      height: 100%;
      display: inline;
      border-width: 0px;
      border-color: transparent;
      padding-left: 5px;
      color:rgba(255, 255, 255, 0.7) ;
    }
  }
  .color {
    width: 90px;
    .color-val {
      margin-left: 8px;
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.menu-item.border-b {
  border-bottom: 1px solid rgba(92, 111, 254, 0.1);
}

.color-item {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50%;
  .color-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &.active {
    border: 1px solid #5c6ffe;
  }
  &:hover {
    border-color: @primary-color;
  }
  &.set {
    background-color: #333;
  }
}
</style>
