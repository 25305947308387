<template>
  <div
    :id="`store-good-id-${good.id}`"
    :class="{
      good: true,
      relative: true,
      skeleton: isTmp,
      'layout-info': infoMenuVisible,
      'layout-col': !infoMenuVisible
    }"
  >
    <!-- 角标 -->
    <im-image
      v-if="isHasCorner"
      :src="good.corner"
      is-lazy
      :scroll-container="lazyScrollContainer"
      class="absolute top-0 right-0 cursor-pointer good-logo corner-logo"
      @click="buy(good)"
    />

    <!-- 商品图片 -->
    <div
      class="flex justify-center cursor-pointer icon"
      @click="buy(good)"
    >
      <im-icon v-if="isTmp" class="text-[36px]" icon="icon-a-emptyproduct" />
      <im-image v-else :src="good.icon" class="relative good-logo" is-lazy :scroll-container="lazyScrollContainer" />
      <div v-if="isSaleOut"
        class="absolute z-50 flex items-center justify-center text-white bg-black good-logo opacity-60">
        {{ $t('siteBuild.store.soldOut') }}</div>
    </div>

    <div class="info-wrapper">
      <div class="popover-wrapper">
        <!-- 商品名称 -->
        <el-popover
          v-if="goodName"
          :append-to-body="!editing"
          placement="bottom"
          width="200"
          trigger="click"
        >
           <div>{{ goodName }}</div>
          <div slot="reference" class="name">{{ goodName }}</div>
        </el-popover>
        <div v-else class="name">{{ goodName }}</div>
        <!-- 商品备注或描述 -->
        <el-popover
          v-if="goodDescription"
          v-model="descPopoverVisible"
          :append-to-body="!editing"
          placement="bottom"
          width="200"
          trigger="click"
        >
          <div>{{ goodDescription }}</div>
          <div
            slot="reference"
            class="descriptions"
          >
            {{ goodDescription }}
          </div>
        </el-popover>
        <div v-else class="descriptions">{{ goodDescription }}</div>
      </div>

      <!-- 可购买时间 -->
      <div v-if="isShowCountdown" class="bottom-wrapper timer-style">
        {{ countdownDays }}:{{ countdownHours }}:{{ countdownMinutes }}:{{ countdownSeconds }}
      </div>
      <!-- 商品价格展示 -->
      <div v-else class="flex flex-wrap items-start justify-between cursor-pointer bottom-wrapper">
        <div class="flex justify-start price-info">
          <div v-if="isTmp" class="price">$XX.XX</div>
          <div v-else class="price-with__original" @click="buy(good)">
            <!-- 真实价格 -->
            <div class="price">{{ realPriceText }}</div>
            <!-- 划线价格 -->
            <div v-if="isShowOriginalPrice" class="line-through price-original">
              {{ originalPriceText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goodMixins from './goodMixins';
export default {
  name: 'NormalGood',

  mixins: [goodMixins],
}
</script>

<style lang="less" scoped>
// 正常有商品数据的样式
.good {
  @apply box-border;
  width: 278px;
  padding-bottom: 13px;
  margin-bottom: 24px;

  .corner-logo {
    width: 70px;
    height: 70px;
    z-index: 1;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  .icon {
    @apply w-full items-center justify-center;
    height: 256px;

    .good-logo {
      @apply w-full flex items-center justify-center;
      width: 210px;
      height: 210px;
      min-width: 145px;
      min-height: 145px;

      :deep(img) {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      :deep(.im-image__error) {
        img {
          @apply w-full;
        }
      }
    }
  }

  .name {
    @apply text-20 font-normal w-full break-all text-ellipsis overflow-hidden;
    margin-bottom: 10px;
    // font-size: 20px;
    // font-weight: normal;
    line-height: 30px;
    // letter-spacing: 0em;
    /* 这里是超出几行省略 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .descriptions {
    @apply text-14 font-normal w-full break-all text-ellipsis overflow-hidden mb-5;
    line-height: 18px;
    min-height: 36px;
    /* 这里是超出几行省略 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .info-wrapper {
    margin: 0 24px;
  }

  .bottom-wrapper {
    .price-info {
      @apply font-normal text-20;

      .currency {
        @apply mr-1;
      }

      .price-original {
        @apply text-14;
      }
    }

    .right {
      .car {
        @apply cursor-pointer;
      }

      .buy-btn {
        .btn {
          @apply text-12 h-7 border-0 flex items-center justify-center p-0;
          min-width: 80px;
          background-color: var(--button-background-color);
          background-image: var(--button-background-image);
          opacity: var(--button-background-opacity);
          border-radius: var(--button-border-radius-px);
          color: var(--button-text-color);
        }

        .btn.is-disabled {
          background: #b3b3b3 !important;
          color: #fff !important;
        }
      }
    }

    &.timer-style {
      @apply flex justify-center items-center left-0 right-0 absolute text-28 cursor-not-allowed;
      height: 50px;
      bottom: 10px;
    }
  }

  // 无商品数据情况下的样式
  &.skeleton {
    .icon {
      .img {
        @apply w-full rounded;
        height: 145px;
        background: rgba(255, 255, 255, 0.12);

        .im-icon {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .bottom-wrapper {
      .right {
        .car {
          @apply cursor-pointer;
        }
      }
    }
  }
}

// 750px 样式
.site-device-750 {
  .good {
    @apply mr-0 w-full h-auto p-0;
    margin: 8px 16px;
    // margin-bottom: 0px;

    &:last-child {
      @apply mb-0;
    }

    .name {
      @apply text-16;
      line-height: 24px;
    }

    .corner-logo {
      width: 50px;
      height: 50px;
    }

    .icon {
      height: unset;

      .good-logo {
        width: 128px;
        height: 128px;
        min-width: 128px;
        min-height: 128px;

        :deep(.im-image__error) {
          img {
            @apply w-full h-full;
          }
        }
      }
    }

    .descriptions {
      @apply text-12;
      -webkit-line-clamp: unset;
      min-height: auto;
    }

    .bottom-wrapper {
      .price-info {
        @apply text-16;
        height: 30px;
        line-height: 30px;

        .price-with__original {
          @apply flex flex-1 justify-between;
        }
      }
    }

    // 移动端布局公用的样式
    .info-wrapper__common {
      @apply flex flex-col justify-between box-border m-0;
      width: calc(100% - 124px);
      padding: 0 16px 0 12px;

      .popover-wrapper {
        .descriptions {
          @apply overflow-hidden text-ellipsis mb-0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 38px;
        }
      }

      .bottom-wrapper {
        @apply flex-col items-start;

        .price-info {
          @apply w-full;

          .price {
            height: 30px;
            line-height: 24px;
          }

          .price-original {
            height: 20px;
            line-height: 24px;
          }
        }

        .right {
          @apply w-full justify-end;
        }
      }
    }

    // 左侧图片，右侧信息的布局方式
    &.layout-info {
      @apply flex;
      margin: 8px;

      .icon {
        width: unset;
      }

      .info-wrapper {
        .info-wrapper__common;

        .bottom-wrapper {
          &.timer-style {
            @apply justify-center items-center;
            bottom: 30px;
            left: 50%;
            right: 10px;
          }
        }
      }
    }

    // 商品两列布局方式
    &.layout-col {
      @apply inline-flex box-border flex-col;
      margin: 8px;
      width: calc(50% - 16px);

      &:last-child {
        @apply mb-4;
      }

      .icon {
        .good-logo {
          @apply w-full h-full;
          width: 163px;
          height: 163px;
          min-width: 163px;
          min-height: 163px;
        }
      }

      .info-wrapper {
        .info-wrapper__common;
        @apply w-full;
        padding: 0 16px 16px;

        .bottom-wrapper {
          &.timer-style {
            @apply justify-center items-center left-0 right-0;
            bottom: 30px;
          }

          .price-info {
            height: 50px;

            .price-with__original {
              @apply flex-col;

              .price {
                height: 30px;
                line-height: 30px;
              }

              .price-original {
                height: 20px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
