<template>
  <div class="login-widget-edit-wrap">
    <menu-item class="menu-item-switch" :pt="'0px'">
      <menu-background
        :background-blur-disabled="true"
        :background-name="$t('menu.backgroundMask')"
        :model="model"
        :image-disabled="true"
      ></menu-background>
    </menu-item>
    <menu-divider />
    <menu-item
      v-if="siteLoginUserInfo"
      class="menu-item-switch"
      :pt="'0px'"
      :label="$t('common.dialog')"
    >
      <menu-background
        color-circle
        blur-circle
        :background-blur-disabled="device !== 'desktop'"
        :image-disabled="true"
        :background-name="$t('menu.backgroundDialog')"
        :background-blur-name="$t('menu.radiusDialog')"
        :model="model.loginBackground"
      ></menu-background>
    </menu-item>
    <menu-item
      v-if="!siteLoginUserInfo"
      class="menu-item-switch"
      :pt="'0px'"
      :label="$t('common.dialog')"
    >
      <menu-background
        color-circle
        blur-circle
        :background-blur-disabled="device !== 'desktop'"
        :image-disabled="true"
        :background-name="$t('menu.backgroundDialog')"
        :background-blur-name="$t('menu.radiusDialog')"
        :model="model.loginBackground"
      ></menu-background>
    </menu-item>
    <menu-item
      v-if="device === 'desktop'"
      :left-circle="true"
      :border-bottom="false"
      :label="$t('edit.pcloginbg')"
      size-tip="(建议比例4:4)"
      class="pt-4"
    >
      <menu-background-image :target="model.pcModalBgImg" event-key="pc-login-bg-image" />
    </menu-item>
    <menu-item
      v-if="device === 'mobile'"
      :left-circle="true"
      :border-bottom="false"
      :label="$t('edit.mbloginbg')"
      class="pt-4"
    >
      <menu-background-image :target="model.mbModalBgImg" event-key="mb-login-bg-image" />
    </menu-item>
    <menu-divider />
    <menu-item class="menu-item-switch" :label="$t('edit.text')" :pt="'0px'">
      <menu-background
        color-circle
        :background-blur-disabled="true"
        :image-disabled="true"
        :background-name="$t('menu.titleText')"
        :model="model.titleTextStyle"
        :fill-eye="false"
      ></menu-background>
      <menu-background
        color-circle
        :background-blur-disabled="true"
        :image-disabled="true"
        :background-name="$t('menu.bodyText')"
        :model="model.mainTextStyle"
        :fill-eye="false"
      ></menu-background>
    </menu-item>
    <menu-divider />
    <menu-item class="menu-item-switch" :label="$t('edit.button')" :pt="'0px'">
      <menu-background
        color-circle
        :background-blur-disabled="true"
        :image-disabled="false"
        :background-name="$t('menu.buttonColor')"
        :model="model.buttonStyle"
      ></menu-background>
      <menu-background
        class="mt-4"
        color-circle
        :background-blur-disabled="true"
        :image-disabled="true"
        :background-name="$t('menu.buttonText')"
        :model="model.buttonTextStyle"
        :fill-eye="false"
      ></menu-background>
    </menu-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SeoSettingModel } from '../../model'
import MenuItem from "~/components/menu/menuItem"
import MenuDivider from "~/components/menu/divider"
import MenuBackground from '~/components/menu/menuBackground.vue'
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";

export default {
  name: "LoginSettingEdit",
  components: {
    MenuItem,
    MenuBackground,
    MenuDivider,
    menuBackgroundImage
  },
  props: {
    model: {
      type: Object,
      default() {
        return new SeoSettingModel()
      }
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {
      tmpModel: {}
    }
  },
  computed: {
    ...mapState({
      siteLoginUserInfo: state => state.user.siteLoginUserInfo,
      userInfo: state => state.user.siteUserInfo || {},
      loginShow: state => state.user.loginShow || false, // 显示登录弹窗
      projectInfo: state => state.project.info,
    }),
  },
  methods: {
    handleShowBackgroundMenu(suggestion) {
      this.$parent && this.$parent.selectSubmenu('background', this.model, {
        suggestion
      })
    }
  }
}
</script>

<style lang="less" scoped>
.save-btn {
  width: 96px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 120px;
  background: @primary-color;
  color: @text-color;
  font-size: 16px;

  &:hover {
    color: @text-light-color;
  }
}
</style>
