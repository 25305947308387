<template>
  <!-- 删除 区块弹窗-->
  <BaseDialog :value="value"
              class="remove-block-dialog"
              width="448px"
              type="warning"
              :modal="true"
              :offset="[56,0,0,0]"
              :modal-append-to-body="true"
              :append-to-body="true"
              :title="$t('menu.deleteBlock')"
              @close="close"
              @change="close"

  >
    <div class="remove-block__body">
      <div class="warning-title text-16 ">
        {{$t('menu.deleteBlockMessage')}}
      </div>
      <div class="warning-desc"></div>
    </div>
    <div class="divider"></div>
    <div class="remove-block_footer">
      <button class="normal-btn" @click.stop="close">{{ $t('menu.saveBlock') }}</button>
      <button class="remove-btn" @click.stop="deleteBlock">{{ $t('menu.deleteBlock') }}</button>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '~/components/base/baseDialog'
export default {
  name: "DeleteBlockDialog",
  components:{
    BaseDialog,
  },
  model:{
    prop: 'value',
    event: 'change'
  },
  props:{
    value: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    close(){
      this.$emit('change',false)
    },
    deleteBlock(){
      this.$emit('delete-block')
    }
  }
}
</script>

<style scoped>
.icon-error{
  width: 16px;
  height: 16px;
}
</style>
