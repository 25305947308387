<template>
  <div>
    <remove-btn :click-fun="handleShowDeleteDialog" />
    <!-- 删除 区块弹窗-->
    <DeleteBlockDialog v-model="visible" @close="visible = false" @delete-block="deleteBlock" />
  </div>
</template>

<script>
import DeleteBlockDialog from './deleteBlockDialog'
import RemoveBtn from "~/components/common/RemoveBtn.vue";

export default {
  name: "DeleteBlockDialogTrigger",
  components:{
    RemoveBtn,
    DeleteBlockDialog,
    // DeleteChildDialog,
  },
  props: {
    isDeleteBlock: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return{
      visible: false,
      isScroll: false,
      scrollTop: 0,
      blockVisible: false
    }
  },
  mounted() {
    //
  },
  methods:{
    close(){
      this.$emit('change',false)
    },
    deleteBlock(){
      this.$emit('delete-block')
      this.$nextTick(()=>{
        this.visible = false
      })
    },
    handleShowDeleteDialog() {
      this.visible = true
    }
  }
}
</script>

<style lang="less" scoped>
@delete-block-height: 48px;
.bottom-handle {
  position: sticky;
  width: 100%;
  height: @delete-block-height;
  left: 0;
  bottom: 0;
}
  .bottom-handle-sticky {
    position: sticky;
    width: 100%;
    left: 0;
    bottom: -24px;
    height: @delete-block-height;
    border-top: 0.5px solid rgba(255, 255, 255, 0.3);
    padding: 0 24px;
    background-color: @dark-bg;

    .delete-svg {
      fill: @text-color-secondary;
      margin-right: 10px;
    }
  }

</style>
